import strings from './strings';

export const ROLES = [
  { code: strings.adherentRoles.president, id: 'President' },
  { code: strings.adherentRoles.boardingMember, id: 'BoardMember' },
  { code: strings.adherentRoles.partnerCommission, id: 'PartnerCommission' },
  { code: strings.adherentRoles.ITCommission, id: 'ITCommission' },
  { code: strings.adherentRoles.CSRCommission, id: 'CSRCommission' },
  { code: strings.adherentRoles.axinvestCommission, id: 'AxinvestCommission' },
  { code: strings.adherentRoles.trainingCommission, id: 'TrainingCommission' },
  { code: strings.adherentRoles.other, id: 'Other' },
];

export const JOBS = [
  { label: strings.adherentJobs.justSAV, value: 'CustomerServiceOnly' },
  { label: strings.adherentJobs.installation, value: 'CustomerServiceAndInstall' },
];

export const ACTIVITIES = [
  { code: strings.adherentAllActivites.gas, id: 'GAZ' },
  { code: strings.adherentAllActivites.heating, id: 'PAC' },
  { code: strings.adherentAllActivites.freshing, id: 'CLIM' },
  { code: strings.adherentAllActivites.bio, id: 'Biomasse' },
  { code: strings.adherentAllActivites.pv, id: 'PV' },
  { code: strings.adherentAllActivites.sanitary, id: 'Sanitaire' },
  { code: strings.adherentAllActivites.service, id: 'Tertiaire' },
];

export const EMPLOYEE_NUMBERS = [
  { label: '1-5', value: 1 },
  { label: '6-10', value: 6 },
  { label: '11-25', value: 11 },
  { label: '26-50', value: 26 },
  { label: '50+', value: 50 },
];

export const SALES_FIGURES = [
  { label: '0-500K', value: 1 },
  { label: '500K-1M', value: 500 },
  { label: '1M-2M', value: 1000 },
  { label: '2M - 4M', value: 2000 },
  { label: '+4M', value: 4000 },
];
